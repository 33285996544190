<template>
    <div>
        <div class="sale-area-container" v-for="(item,index) in dataList" :key="index">
           <el-form
            :model="detailForm"
            :rules="detailRule"
            ref="detailForm"
            label-width="100px"
            class="demo-ruleForm"
           >
                <el-row>
                    <el-col :span="24">
                      <div class="arrItemTitle">
                        <div class="arrItemTitle-left">
                          <p>
                            子科目信息{{index + 1}}
                          </p>
                          <div>
                            <el-button v-if="index === 0" type="text" @click="addArrItem()">
                              <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
                            </el-button>
                            <el-button v-if="index>0" type="text" @click="del(index)">
                              <i style="font-size: 20px" class="el-icon-delete"></i>
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="科目名称" prop="subSubjectName">
                            <div class="cost-select" @click="openHandleClick('subject', 'subject_list', 'budgetSubjectsCode', index,null)">
                                <el-input :readonly="true" v-model="item.subSubjectName" placeholder="科目名称"></el-input>
                                <div class="clear-icon">
                                    <i class="el-icon-circle-close" @click="clearFn($event,'subject',index)"></i>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="科目组织"  prop="orgName">
                            <div class="cost-select1  el-textarea el-input--small" style="min-height:32px;" @click="openHandleClick('org', 'org_list', 'orgCode', index,null)">
                                <el-tag
                                    v-for="(aItem, k) in item.subjectsSubOrgReqVos"
                                    :key="k"
                                    closable
                                    @close="handleClose(aItem)"
                                    >{{ aItem.orgName }}</el-tag>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="有效期" prop="name">
                            <el-date-picker
                                v-model="item.executeTime"
                                @change="changeExecuteTime($event,index)"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注" prop="remarks">
                          <el-input type="textarea"
                            placeholder="请输入备注"
                            v-model="item.remarks">
                          </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div>
          <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
        </div>
    </div>
</template>
<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'SubSubjectsForm',
  props: {
    subjectData: {
      type: Array,
      default: () => [],
    },
    budgetSubjectsCode: String,
    budgetSubjectsName: String,
  },
  components: {
    SelectConfig,
  },
  data() {
    return {
      detailForm: {},
      detailRule: {},
      index: '',
      chooseModule: '',
      dataList: [],
    };
  },
  watch: {
    subjectData(newVal, oldVal) {
      this.dataList = newVal;
    },
  },
  created() {},
  methods: {
    del(index) {
      this.dataList.splice(index, 1);
    },
    handleClose(item) {
      const data = this.dataList[this.index].subjectsSubOrgReqVos.filter(
        (v) => v.orgCode !== item.orgCode,
      );
      this.dataList[this.index].subjectsSubOrgReqVos = data;
    },
    changeExecuteTime(val, index) {
      if (val) {
        const [startTime, endTime] = val;
        this.dataList[index].startTime = startTime;
        this.dataList[index].endTime = endTime;
      } else {
        this.dataList[index].startTime = null;
        this.dataList[index].endTime = null;
      }
    },
    // 打开点击弹窗
    openHandleClick(module, list, key, index, paramData) {
      this.index = index;
      this.chooseModule = module;
      let params = {};
      if (module === 'org') {
        params = {
          functionCode: list,
          data: this.dataList[index].subjectsSubOrgReqVos || [],
          idKey: key,
          paramData: {
            enableStatus: '009',
            orgType: 'area',
          },
        };
      } else {
        params = {
          functionCode: list,
          data: [],
          idKey: key,
          paramData,
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 获取弹窗选中数据
    onGetSelect(data) {
      const { index } = this;
      if (this.chooseModule === 'org') {
        if (data.length > 0) {
          const list = this.getReset(data, [], 'orgCode');
          this.dataList[index].subjectsSubOrgReqVos = list;
        } else {
          this.dataList[index].subjectsSubOrgReqVos = [];
        }
      } else if (this.chooseModule === 'subject') {
        if (data.length > 0) {
          if (this.dataList[index].subjectCode === data[0].budgetSubjectsCode) {
            this.$message.error('子预算科目不能与主预算科目相同，请重新选择！');
            return;
          }
          this.dataList[index].subSubjectCode = data[0].budgetSubjectsCode;
          this.dataList[index].subSubjectName = data[0].budgetSubjectsName;
        } else {
          this.dataList[index].subSubjectCode = null;
          this.dataList[index].subSubjectName = null;
        }
      }
    },
    getFormData() {
      return this.dataList;
    },
    addArrItem() {
      this.dataList.push({
        subjectCode: this.budgetSubjectsCode,
        subjectName: this.budgetSubjectsName,
        subSubjectCode: '',
        subSubjectName: '',
        subjectsSubOrgReqVos: [],
        startTime: '',
        endTime: '',
        executeTime: '',
        remarks: '',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sale-area-container{
    position: relative;
    border-bottom: 1px solid #eee;
    padding-top:20px;
    .arrItemTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .arrItemTitle-left{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
          margin: 0 20px 0 0;
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          font-weight: 600;
        }
      }
    }
    .del-btn{
      position: absolute;
      top: -12px;
      right: 0;
      width: 20px;
      color: red;
    }
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
.cost-select1 {
  position: relative;
  border: 1px solid #dddfe6;
  border-radius: 5px;
  padding: 5px;
}
</style>
