var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { type: "text", icon: "el-icon-upload2" },
          on: { click: _vm.uploadInvoiceModal },
        },
        [_vm._v("上传发票")]
      ),
      _c(
        "vxe-table",
        {
          ref: "xTable1",
          attrs: { "row-key": "", "row-id": "id", data: _vm.getinvoiceData },
        },
        [
          _c("vxe-table-column", {
            attrs: { title: "开票方名称", field: "salerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "开票方税号", field: "salerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "购货方名称", field: "sallerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "购货方纳税人识别号", field: "sallerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "收款方企业名称", field: "buyerName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "收款方企业税号", field: "buyerTaxNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票号码", field: "invoiceNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "价税合计总额", field: "totalAmount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "总金额(不含税)", field: "amount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "总税额", field: "taxAmount" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "税率", field: "taxRate" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票流水号", field: "serialNo" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "发票日期", field: "invoiceDate" },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              width: "200",
              align: "center",
              "show-overflow": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-check" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.closeForm },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: _vm.formName,
            tag: "component",
            attrs: { formConfig: _vm.formConfigData },
            on: {
              closeModal: _vm.closeModal,
              setinvoiceData: _vm.setinvoiceData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }