<template>
    <div>
        <el-button type="text" icon="el-icon-upload2" @click="uploadInvoiceModal">上传发票</el-button>
        <vxe-table row-key row-id="id" ref="xTable1" :data="getinvoiceData">
            <vxe-table-column title="开票方名称" field="salerName"></vxe-table-column>
            <vxe-table-column title="开票方税号" field="salerTaxNo"></vxe-table-column>
            <vxe-table-column title="购货方名称" field="sallerName"></vxe-table-column>
            <vxe-table-column title="购货方纳税人识别号" field="sallerTaxNo"></vxe-table-column>
            <vxe-table-column title="收款方企业名称" field="buyerName"></vxe-table-column>
            <vxe-table-column title="收款方企业税号" field="buyerTaxNo"></vxe-table-column>
            <vxe-table-column title="发票号码" field="invoiceNo"></vxe-table-column>
            <vxe-table-column title="价税合计总额" field="totalAmount"></vxe-table-column>
            <vxe-table-column title="总金额(不含税)" field="amount"></vxe-table-column>
            <vxe-table-column title="总税额" field="taxAmount"></vxe-table-column>
            <vxe-table-column title="税率" field="taxRate"></vxe-table-column>
            <vxe-table-column title="发票流水号" field="serialNo"></vxe-table-column>
            <vxe-table-column title="发票日期" field="invoiceDate"></vxe-table-column>
            <vxe-table-column title="操作" width="200" align="center" show-overflow>
              <template #default="{ row }">
                <el-button type="text" size="mini" @click="handleRemove(row)"
                  >删除</el-button
                >
              </template>
            </vxe-table-column>
        </vxe-table>
        <div slot="footer" class="dialog-footer">
            <el-button type="danger" @click="onSubmit" size="small" icon="el-icon-check">保存</el-button>
            <el-button type="danger" @click="closeForm" size="small" icon="el-icon-close">关闭</el-button>
        </div>
        <Modal
        :modalConfig.sync="modalConfig"
        :visible.sync="modalConfig.visible"
        @onClose="closeModal"
        >
            <component
                :ref="formName"
                :is="formName"
                :formConfig="formConfigData"
                @closeModal="closeModal"
                @setinvoiceData="setinvoiceData"
            />
        </Modal>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import Modal from '../../../../components/modal';
import InvoiceModal from './invoice_modal.vue';
import request from '../../../../utils/request';

export default {
  name: 'invoice_table',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      modalConfig: {
        title: '标题',
        type: 'Full',
        visible: false,
      },
      formConfigData: {},
      formName: 'InvoiceModal',
      getinvoiceData: [],
    };
  },
  components: {
    Modal,
    InvoiceModal,
  },
  created() {},
  methods: {
    setinvoiceData(val) {
      const data = (val.data || []).map((v) => {
        const obj = v;
        obj.objectId = this.formConfig.bussinesId;
        obj.id = '';
        return obj;
      });
      const newList = [];
      if (this.getinvoiceData && this.getinvoiceData.length > 0) {
        this.getinvoiceData.forEach((v) => {
          data.forEach((k) => {
            if (v.invoiceNo !== k.invoiceNo && v.invoiceCode !== k.invoiceCode) {
              newList.push(...v);
            }
          });
        });
        this.getinvoiceData.push(...newList);
        // this.getinvoiceData.push(...data);
      } else {
        this.getinvoiceData = data;
      }
    },
    closeModal() {
      this.modalConfig.visible = false;
    },
    handleRemove(row) {
      const index = this.getinvoiceData.findIndex((a) => a.invoiceNo === row.invoiceNo && a.invoiceCode === row.invoiceCode);
      this.getinvoiceData.splice(index, 1);
    },
    // 上传发票
    uploadInvoiceModal() {
      this.formName = 'InvoiceModal';
      this.modalConfig.title = '上传发票';
      this.modalConfig.visible = true;
      this.modalConfig.type = 'full';
      this.formConfigData = this.formConfig;
    },
    onSubmit() {
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      request
        .post('/tpm/tpmscaninvoice/saveByList', this.getinvoiceData)
        .then((res) => {
          if (res.success) {
            this.$nextTick(() => {
              loadingInstance.close();
            });
            this.$emit('onClose');
            this.$message.success('保存成功');
          } else {
            this.$nextTick(() => {
              loadingInstance.close();
            });
          }
        }).catch((err) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
        });
    },
    closeForm() {
      this.$emit('onClose');
    },
  },
};
</script>
