<template>
    <div>
        <vxe-table row-key row-id="id" ref="xTable1" :data="getinvoiceData">
            <vxe-table-column title="开票方名称" field="salerName"></vxe-table-column>
            <vxe-table-column title="开票方税号" field="salerTaxNo"></vxe-table-column>
            <vxe-table-column title="销货方名称" field="sallerName"></vxe-table-column>
            <vxe-table-column title="销货方纳税人识别号" field="sallerTaxNo"></vxe-table-column>
            <vxe-table-column title="收款方企业名称" field="buyerName"></vxe-table-column>
            <vxe-table-column title="收款方企业税号" field="buyerTaxNo"></vxe-table-column>
            <vxe-table-column title="发票代码" field="invoiceCode"></vxe-table-column>
            <vxe-table-column title="发票号码" field="invoiceNo"></vxe-table-column>
            <vxe-table-column title="价税合计总额" field="totalAmount"></vxe-table-column>
            <vxe-table-column title="总金额(不含税)" field="amount"></vxe-table-column>
            <vxe-table-column title="总税额" field="taxAmount"></vxe-table-column>
            <vxe-table-column title="税率（%）" field="taxRate"></vxe-table-column>
            <vxe-table-column title="发票流水号" field="serialNo"></vxe-table-column>
            <vxe-table-column title="发票日期" field="invoiceDate"></vxe-table-column>
            <vxe-table-column title="操作" width="100" align="center" show-overflow>
              <template #default="{ row }">
                <el-button type="text" size="mini" v-if="!disabled" @click="deletRow(row)">删除</el-button>
              </template>
            </vxe-table-column>
        </vxe-table>
        <div slot="footer" class="dialog-footer">
            <el-button type="danger" @click="closeForm" size="small" icon="el-icon-close">关闭</el-button>
        </div>
    </div>
</template>
<script>
import request from '../../../../utils/request';

export default {
  name: 'invoice_detail',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      getinvoiceData: [],
    };
  },
  components: {},
  created() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      request
        .post('/tpm/tpmscaninvoice/list', { objectId: this.formConfig.objectId })
        .then((res) => {
          if (res.success) {
            this.getinvoiceData = res.result;
          }
        });
    },
    // 删除行
    deletRow(row) {
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request
          .post('/tpm/tpmscaninvoice/delete', [row.id])
          .then((res) => {
            if (res.success) {
              this.getinvoiceData.forEach((item, index) => {
                if (item.id === row.id) {
                  this.getinvoiceData.splice(index, 1);
                }
              });
              this.$message({ type: 'success', message: '删除成功!' });
            }
          });
      });
    },
    closeForm() {
      this.$emit('onClose');
    },
  },
};
</script>
