import BasePage from '../../../../../../components/table_page';
import { ProcessLog } from '../../../../../../modules';
import Form from '../form';
import request from '../../../../../../utils/request';
import Reconsideration from '../components/reconsideration';
import ActivityClose from '../components/activity_close';
import ModifyTheNumberOfExecutions from '../components/modify_the_number_of_executions';
import LogTable from '../components/log_table.vue';
import ChangePerson from '../components/change_person';
import CancelObtainingEvidence from '../components/cancel_obtaining_evidence';
import InvoiceDetail from '../components/invoice_detail.vue';

export default {
  name: 'marketing_activity_list',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'project',
      },
    };
  },
  components: {
    Form,
    ProcessLog,
    Reconsideration,
    ActivityClose,
    ModifyTheNumberOfExecutions,
    LogTable,
    ChangePerson,
    CancelObtainingEvidence,
    InvoiceDetail,
  },
  created() {
    this.getConfigList('marketing_activity_list');
  },
  methods: {

    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const {
        approveStatus, processCode, isAllAudit, allowOperationRecord,
      } = row;
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === 'interrupt'
          || approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved'
          || approveStatus === 'supervise_cancel')
      ) {
        return false;
      }
      if (
        approveStatus
        && code === 'submit_approval'
        && (approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }
      if ((code === 'reconsideration' && approveStatus !== 'approved') || (code === 'activities_close' && approveStatus !== 'approved') || (code === 'modify_the_number_of_executions' && approveStatus !== 'approved')) {
        return false;
      }
      if (code === 'cancel_obtaining_evidence' && (approveStatus !== 'approved' || isAllAudit !== 'N')) {
        return false;
      }
      if (code === 'log' && allowOperationRecord !== 'Y') {
        return false;
      }
      return true;
    },

    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request.get('/tpm/tpmActController/query', {
          id: row.actId || row.id,
        })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.budgetVos = rowData.editBudgetVoList;
              rowData.saveType = '5';
              rowData.id = rowData.actId || rowData.id;
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmActController/approve' });
            }
          });
      } else if (code === 'activities_close') {
        this.formName = 'ActivityClose';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          progressList: [],
        };
        this.modalConfig.title = '活动关闭';
        this.openFull();
        // request.post('/activiti/taActBaseController/getSubmitActiviti', {}).then((res) => {
        //   if (res.success) {
        //     const tempArr = [];
        //     const tempList = res.result.details ? res.result.details : [];
        //     for (const item of tempList) {
        //       tempArr.push({
        //         label: item.processName,
        //         value: item.processKey,
        //       });
        //     }

        //     this.formName = 'ActivityClose';
        //     this.formConfig = {
        //       ...this.formConfig,
        //       code,
        //       row,
        //       progressList: tempArr,
        //     };
        //     this.modalConfig.title = '活动关闭';
        //     this.openFull();
        //   }
        // });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      } else if (code === 'log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };

        this.modalConfig.title = '日志';

        this.formName = 'LogTable';
        this.openFull();
      } else if (code === 'change_person') {
        this.formName = 'ChangePerson';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = '变更负责人';
        this.openFull();
      } else if (code === 'reconsideration') { // 发起复议
        this.formName = 'Reconsideration';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          progressList: [],
        };
        this.modalConfig.title = '发起复议';
        this.openFull();
        // request.post('/activiti/taActBaseController/getSubmitActiviti', {}).then((res) => {
        //   if (res.success) {
        //     const tempArr = [];
        //     const tempList = res.result.details ? res.result.details : [];
        //     for (const item of tempList) {
        //       tempArr.push({
        //         label: item.processName,
        //         value: item.processKey,
        //       });
        //     }

        //     this.formName = 'Reconsideration';
        //     this.formConfig = {
        //       ...this.formConfig,
        //       code,
        //       row,
        //       progressList: tempArr,
        //     };
        //     this.modalConfig.title = '发起复议';
        //     this.openFull();
        //   }
        // });
      } else if (code === 'modify_the_number_of_executions') {
        this.formName = 'ModifyTheNumberOfExecutions';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          progressList: [],
        };
        this.modalConfig.title = '修改活动执行次数';
        this.openFull();
      } else if (code === 'cancel_obtaining_evidence') {
        this.formName = 'CancelObtainingEvidence';
        this.modalConfig.height = '400px';
        this.modalConfig.width = '600px';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          progressList: [],
        };
        this.modalConfig.title = '取消执行查看';
        this.openModal();
        // this.$confirm('确认要取消该活动在核销时的SFA执行查看？', '提示', {
        //   confirmButtonText: '确认',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        //   .then(() => {
        //     request.get('/tpm/tpmActExt/updateSfaExecuteDataStatus', { id: row.actId || row.id }).then((res) => {
        //       if (res.success) {
        //         this.$message({
        //           type: 'success',
        //           message: '操作成功',
        //         });
        //         this.getList();
        //       }
        //     });
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: 'info',
        //       message: '已取消',
        //     });
        //   });
      } else if (code === 'view_invoice') {
        this.formName = 'InvoiceDetail';
        this.formConfig = {
          objectId: row.actId,
        };
        this.modalConfig.title = '查看发票';
        this.openFull();
      }
    },

    // 提交审批回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
        data: 'activity',
      };

      this.$emit('approve', configParams);
    },
  },
};
