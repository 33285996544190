<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { applyCode: this.propsObj.row.applyCode, lineCode: this.propsObj.row.lineCode };
    this.getConfigList('open_record');
  },
  methods: {

  },
};
</script>
